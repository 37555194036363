import env from "../utils/env";
export function getCookieConsent() {
    if (env.iOsOnly) {
        const consent = {
            theme: true,
        };
        return consent;
    }
    return JSON.parse(localStorage.getItem('cookieconsent'));
}
export function setCookieConsent(newCookieConsent) {
    localStorage.setItem('cookieconsent', JSON.stringify(newCookieConsent));
    if (!newCookieConsent.theme) {
        localStorage.removeItem('darkmode');
    }
}
