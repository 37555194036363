import { createRouter, createWebHistory } from '@ionic/vue-router';
const routes = [
    { path: '/', component: () => import('../pages/AppList.vue') },
    // login paths
    { path: '/login', component: () => import('../pages/Login.vue') },
    { path: '/cb', component: () => import('../pages/LoginCallback.vue') },
    { path: '/update', component: () => import('../pages/Update.vue') },
    // settings and legal paths
    { path: '/settings', component: () => import('../pages/Settings.vue') },
    { path: '/cookie', component: () => import('../pages/Cookie.vue') },
    { path: '/agb', component: () => import('../pages/legal/AGB.vue') },
    { path: '/customerinformation', component: () => import('../pages/legal/CustomerInformation.vue') },
    { path: '/impress', component: () => import('../pages/legal/Impress.vue') },
    { path: '/privacy', component: () => import('../pages/legal/Privacy.vue') },
    { path: '/licenses', component: () => import('../pages/legal/Licenses.vue') },
    // app view
    { path: '/app/:appId', component: () => import('../pages/App.vue'), props: true },
    { path: '/app/:appId/report', component: () => import('../pages/ReportApp.vue'), props: true },
    { path: '/app/:appId/settings', component: () => import('../pages/AppSettings.vue'), props: true }
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});
export default router;
