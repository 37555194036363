/* eslint-disable @typescript-eslint/camelcase */
import PouchDB from 'pouchdb';
import PouchDBFind from 'pouchdb-find';
import CordovaSqliteAdapter from 'pouchdb-adapter-cordova-sqlite';
import env from '../../utils/env';
PouchDB.plugin(PouchDBFind);
export function dbReady() {
    return new Promise((resolve) => {
        if (env.iOsOnly) {
            document.addEventListener('deviceready', async () => {
                ///@ts-ignore
                window.cordova.sqlitePlugin = window.sqlitePlugin;
                ///@ts-ignore
                console.info('SQLite plugin is installed?: ' + (!!window.cordova.sqlitePlugin));
                PouchDB.plugin(CordovaSqliteAdapter);
                resolve();
            });
        }
        else {
            resolve();
        }
    });
}
const openDbs = {};
export async function getDB(name) {
    const pouchDBOptions = {
        adapter: env.iOsOnly ? 'cordova-sqlite' : 'idb',
        iosDatabaseLocation: 'Library',
        auto_compaction: true,
        deterministic_revs: false,
    };
    if (!openDbs[name]) {
        openDbs[name] = new PouchDB(name, pouchDBOptions);
        console.debug(`[PouchDB] opend db connection ${name} with adapter ${pouchDBOptions.adapter}`);
    }
    if (name === 'record') {
        const createIndexResponse = await openDbs['record'].createIndex({
            index: {
                fields: ['appId', 'createDate'],
                ddoc: 'ci',
                name: 'appid'
            },
        });
        console.log(`[PouchDB] create Index for record: ${createIndexResponse.result}`);
    }
    return openDbs[name];
}
export async function clearAllDBs() {
    try {
        console.time('clearAllDBs');
        const appDb = await getDB('app');
        const ctrlDB = await getDB('controlOrder');
        const fileDB = await getDB('file');
        const recordDB = await getDB('record');
        const recordMutationDB = await getDB('recordMutation');
        await appDb.destroy();
        await ctrlDB.destroy();
        await fileDB.destroy();
        await recordDB.destroy();
        await recordMutationDB.destroy();
        delete openDbs.app;
        delete openDbs.controlOrder;
        delete openDbs.file;
        delete openDbs.record;
        delete openDbs.recordMutation;
        console.timeEnd('clearAllDBs');
        console.info('[PouchDB] cleared all data dbs');
        return {};
    }
    catch (error) {
        console.timeEnd('clearAllDBs');
        console.warn('[PouchDB] failed to clear data dbs');
        return { error };
    }
}
export async function clearAllRecords() {
    try {
        console.time('clearAllRecords');
        const fileDB = await getDB('file');
        const recordDB = await getDB('record');
        const recordMutationDB = await getDB('recordMutation');
        await fileDB.destroy();
        await recordDB.destroy();
        await recordMutationDB.destroy();
        delete openDbs.file;
        delete openDbs.record;
        delete openDbs.recordMutation;
        console.timeEnd('clearAllRecords');
        console.info('[PouchDB] cleared all records');
        return {};
    }
    catch (error) {
        console.timeEnd('clearAllRecords');
        console.warn('[PouchDB] failed to clear all records');
        return { error };
    }
}
