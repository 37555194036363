import { IonicVue } from '@ionic/vue';
import { createApp } from 'vue';
import App from './App.vue';
import i18n from './services/i18n';
import router from './services/router';
import env from './utils/env';
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';
/* CSS variables for ionic theming */
import './theme.css';
/* enable dark mode if system prefers it */
import './services/theme';
const app = createApp(App);
app.use(IonicVue);
app.use(router);
///@ts-ignore
app.use(i18n);
app.mount('#app');
if (env.iOsOnly)
    import('./ios');
else
    import('./android');
