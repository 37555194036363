import { IonApp, IonRouterOutlet, IonSpinner, } from '@ionic/vue';
import { useRouter } from 'vue-router';
import { defineComponent, onMounted, ref } from 'vue';
import { dbReady } from './services/db/db';
import { getCookieConsent } from './services/cookie';
import env from './utils/env';
export default defineComponent({
    components: {
        IonApp,
        IonRouterOutlet,
        IonSpinner,
    },
    setup() {
        const ready = ref(false);
        const router = useRouter();
        onMounted(async () => {
            await dbReady();
            if (!getCookieConsent() && !env.iOsOnly) {
                console.info('cookie consent missing');
                router.push('/cookie');
            }
            ready.value = true;
        });
        console.log('create app component');
        return {
            ready
        };
    },
});
