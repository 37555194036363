import { createI18n } from 'vue-i18n';
export const i18n = createI18n({
    legacy: false,
    locale: 'de',
    fallbackLocale: 'en',
    messages: {
        en: {
            loginBtn: 'Login'
        },
        de: {
            loginBtn: 'Einloggen'
        }
    }
});
export function setLanguage(lang) {
    i18n.global.locale.value = lang;
}
export default i18n;
