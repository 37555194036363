import { ref } from "vue";
import { getCookieConsent } from "./cookie";
const systemThemeWatcher = window.matchMedia('(prefers-color-scheme: dark)');
const userTheme = window.localStorage.getItem('darkmode');
export const isDark = ref((userTheme !== null && userTheme !== void 0 ? userTheme : (systemThemeWatcher.matches ? 'dark' : 'light')) === 'dark');
function toggleThemeMode(mode) {
    document.body.classList[mode === 'dark' ? 'add' : 'remove']('dark');
}
export function setThemeMode(mode) {
    console.info(`[Theme] change to ${mode} mode`);
    if (getCookieConsent().theme) {
        window.localStorage.setItem('darkmode', mode);
    }
    toggleThemeMode(mode);
    isDark.value = mode === 'dark';
}
console.info(`[Theme] System preferes ${systemThemeWatcher.matches ? 'dark' : 'light'}, the user preferes ${userTheme}`);
toggleThemeMode(userTheme !== null && userTheme !== void 0 ? userTheme : (systemThemeWatcher.matches ? 'dark' : 'light'));
